<template>
  <div class="playground-generals-inf-container container-fluid">
    <b-card class="playground-generals-inf-card">
      <div class="row-style">
        <d-body-nav-bar
          :tabs="[]"
          position="float-right"
          :title="'views.playground.steps.step-2.calendar-choice.general.title'"
          :title-class="'topnav-title-red-light'"
        />
      </div>
      <d-table
        identifier="timetable"
        :tablefields="tableFields"
        :items="items"
        :per-page="itemsPerPage"
        :total-rows="totalRows"
        :current-page="currentPage"
        :is-busy="isBusy"
        :show-pagination="true"
        :tr-cursor-pointer="true"
        @row-clicked="onRowClick"
        @current-page:update="onCurrentPageUpdate"
        @on:pagination-model:update="onPaginationModelChange"
        @on:radio-button-check="onRowCheck"
      >
      </d-table>
    </b-card>
  </div>
</template>
<script>
import {
  deleteTimetable,
  getPlaygroundTimetables,
  getTimetables,
  putTimetable
} from "@api/doinsport/services/timetable/timetable.api";
import Timetable from "@/classes/doinsport/Timetable";
import {fromIdToIriReference, fromIriReferenceToId, unAssign} from "@/utils/form";

export default {
  components: {
    NewCalendarModal: () => import('@custom/playground/new/step-2/NewCalendarModal')
  },
  data: () => ({
    display: false,
    items: [],
    selectedRow: null,
    itemsPerPage: 10,
    totalRows: 1,
    currentPage: 1,
    isBusy: false,
    cachePage: 1,
    inProgress: false,
  }),
  props: {
    timetable: {
      type: Object,
      default: this
    },
    playground: {
      type: Object,
      default: this
    },
    calendarsListSelection: {
      type: Array,
      default: this
    },
    playgroundTimetables: {
      type: Array,
      default: this
    },
  },
  computed: {
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center w-5' : 'text-left';
    },
    alignCellFromToToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center w-25' : 'text-left';
    },
    tableFields() {
      return [
        {key: "singleSelection", class: this.alignCellsToLeft, label: '', sortable: false},
        {key: "name", label: this.$t("views.playground.steps.step-2.calendar-choice.table.wording"), sortable: false},
        {
          key: "fromDate",
          class: this.alignCellFromToToLeft,
          label: this.$t("views.playground.steps.step-2.calendar-choice.table.from"),
          sortable: false
        },
        {
          key: "toDate",
          class: this.alignCellFromToToLeft,
          label: this.$t("views.playground.steps.step-2.calendar-choice.table.until"),
          sortable: false
        },
        {
          key: "daysOfTheWeek",
          label: this.$t("views.playground.steps.step-2.calendar-choice.table.days"),
          sortable: false
        },
      ];
    }
  },
  methods: {
    onRowClick(row) {
      this.onRowCheck(row);
    },
    onRowCheck(row) {
      row.singleSelection = true;
      const findRow = this.items.find((el) => el.id === row.id);

      if ("undefined" !== typeof findRow) {
        this.$bus.$emit('on:timetable:check', findRow);
        this.selectedRow = findRow;
        for (const item of this.items) {
          if (item.id !== findRow.id) {
            item.singleSelection = false;
          } else {
            item.singleSelection = true;
          }
        }
      }

    },
    onPaginationModelChange(page) {
      this.cachePage = page;
    },
    onCurrentPageUpdate(page) {
      this.loadTimetables(this.itemsPerPage, page, null);
    },
    onTimetableCreated(data) {
      Object.assign(data, {calendarStatus: true});

      this.loadTimetables(this.itemsPerPage, this.cachePage, data);
    },
    loadTimetables(itemsPerPage, page, data) {
      this.isBusy = true;
      this.items = [];

      getPlaygroundTimetables(itemsPerPage, page, this.$route.params.id)
        .then((response) => {
          this.totalRows = response.data['hydra:totalItems'];
          this.itemsPerPage = itemsPerPage;
          let lastRow = null;
          for (const timetable of response.data['hydra:member']) {
            const timetableClass = new Timetable(timetable, {serialize: true});

            Object.assign(timetableClass, {actions: ['edit', 'delete']});


            Object.assign(timetableClass, {blocks: timetable.blocks});
            Object.assign(timetableClass, {iri: timetable['@id']});

            if (this.selectedRow !== null && timetable.id === this.selectedRow.id) {
              Object.assign(timetableClass, {singleSelection: true});
              lastRow = timetableClass;
            } else {
              Object.assign(timetableClass, {singleSelection: false});
            }

            // check on update

            this.items.push(timetableClass);
          }
          if (this.selectedRow !== null) {
            this.$bus.$emit('on:timetable:check', lastRow);
          }
        })
        .finally(() => {
          this.isBusy = false;
          if (data) {
            this.onRowCheck(data);
          }
        })
      ;
    }
  },
  beforeDestroy() {
    try {
      this.$bus.$off('refresh-timetables');
    } catch (e) {
    }
  },
  mounted() {
    this.loadTimetables(this.itemsPerPage, this.currentPage, null);

    this.$bus.$on('refresh-timetables', (data) => {
      this.loadTimetables(this.itemsPerPage, this.currentPage, null);
    });

  }
}
</script>
<style scoped>
.playground-generals-inf-card .card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 200px;
  padding: 1.25rem;
}

.playgraound-general-title {
  text-align: left;
  font: normal normal 900 22px Avenir;
  letter-spacing: 0px;
  color: #FF0101;
  opacity: 1;
}

.playground-generals-inf-container .card {
  box-shadow: 0px 10px 16px #E2E1E6;
  border-radius: 8px;
}

.w-10 {
  width: 10%;
}
</style>
